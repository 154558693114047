import discordBot from "../../assets/discordbot.jpg";
import aiVerse from "../../assets/aiverse.png";
import imageAI from "../../assets/imageAI.jpg";
import branx from "../../assets/branx-raw.png";
import blockchain from "../../assets/blockchain.png";
import guzheng from "../../assets/guzheng.jpg";
import aiSummarizer from "../../assets/atom.png";
import terminal from "../../assets/terminal.png";
import bcstamp from "../../assets/bcstamp.png";
import quizbraniacbg from "../../assets/quizbraniacbg.png";
import shirtify from "../../assets/shirtify.png";
import portfolio from "../../assets/portfolio.jpg";
import branlit from "../../assets/branlit.png";
import sawitverse from "../../assets/sawitverse.png";

export const projectsData = [
    {
        id: 1,
        image: branx,
        title: 'BranX',
        category: 'AI',
        link: 'https://branx.vercel.app/',
    },
    {
        id: 2,
        image: sawitverse,
        title: 'Sawitverse',
        category: 'App',
        link: 'https://b3st.vercel.app/',
    },
    {
        id: 3,
        image: branlit,
        title: 'Branlit',
        category: 'AI',
        link: 'https://branlit.streamlit.app/',
    },
    {
        id: 4,
        image: shirtify,
        title: 'Shirtify',
        category: 'AI',
        link: 'https://shirtify-me.vercel.app/',
    },
    {
        id: 5,
        image: bcstamp,
        title: 'BranCoin - Landing Page',
        category: 'web',
        link: 'https://brancoin-crypto.vercel.app/',
    },
    {
        id: 6,
        image: quizbraniacbg,
        title: 'QuizBraniac',
        category: 'AI',
        link: 'https://quizbraniac.vercel.app/',
    },
    {
        id: 7,
        image: aiVerse,
        title: 'AI-verse',
        category: 'web',
        link:'https://ai-verse.vercel.app/',
    },
    {
        id: 15,
        image: portfolio,
        title: 'AI Image Generator',
        category: 'AI',
        link: 'https://imagegeneratorai-6e92d.web.app/',
    },
    {
        id: 16,
        image: blockchain,
        title: 'Blockchain Web3 Application',
        category: 'web',
        link: 'https://blockchainapp-4e293.web.app/',
    },
    {
        id: 17,
        image: terminal,
        title: 'Terminal Website',
        category: 'web',
        link: 'https://bbkxterminal.web.app/',
    },
    {
        id: 18,
        image: aiSummarizer,
        title: 'AI Summarizer',
        category: 'AI',
        link: 'https://brandongpt-summarizer.web.app/',
    },
    {
        id: 19,
        image: discordBot,
        title: 'Discord Bot',
        category: 'app',
        link: 'https://discord.com/oauth2/authorize?client_id=816611024362668122&permissions=156766824512&scope=bot',
    },
    {
        id: 20,
        image: guzheng,
        title: 'Guzheng Chatbot',
        category: 'app',
        link: 'https://app.engati.com/static/standalone/bot.html?bot_key=63ef367dfa364830&env=p',
    },
    // {
    //     id: 21,
    //     image: iservproto,
    //     title: 'Cleaning Service System Prototype',
    //     category: 'design',
    //     link: 'https://www.figma.com/proto/SVkiYqr2ZmOmpqZ2Y7AcnW/iServ-Prototype?node-id=1%3A966&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A966',
    // },
    // {
    //     id: 22,
    //     image: aqualert,
    //     title: 'Early System Mobile App Prototype',
    //     category: 'design',
    //     link: 'https://www.figma.com/proto/1FBqVvr9o5waVhEm4CIT8s/AquaAlert-Prototype?node-id=1%3A359&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A359',
    // },
    // {
    //     id: 23,
    //     image: jomgreen,
    //     title: 'Recycling Web App Prototype',
    //     category: 'design',
    //     link: 'https://www.figma.com/proto/0MuKrTZeKjjm5eU8SvwJOB/JomGreen-Prototype?node-id=1%3A1425&scaling=scale-down&page-id=1%3A2&starting-point-node-id=1%3A1439',
    // },
    // {
    //     id: 24,
    //     image: connectfour,
    //     title: 'Connect Four',
    //     category: 'game',
    //     link: 'https://github.com/bbkx226/Games/blob/main/connectfour.py',
    // },
    // {
    //     id: 25,
    //     image: pong,
    //     title: 'Pong',
    //     category: 'game',
    //     link: 'https://github.com/bbkx226/Games/blob/main/pong.py',
    // },
    // {
    //     id: 26,
    //     image: snake,
    //     title: 'Snake',
    //     category: 'game',
    //     link: 'https://github.com/bbkx226/Games/blob/main/snake.py',
    // },
    // {
    //     id: 27,
    //     image: tetris,
    //     title: 'Tetris',
    //     category: 'game',
    //     link: 'https://github.com/bbkx226/Games/blob/main/tetris.py',
    // },
    // {
    //     id: 28,
    //     image: sketch,
    //     title: 'Etch A Sketch',
    //     category: 'beginner',
    //     link: 'https://bbkx226.github.io/EtchASketch/',
    // },
    // {
    //     id: 29,
    //     image: rps,
    //     title: 'Rock Paper Scissor',
    //     category: 'beginner',
    //     link: 'https://bbkx226.github.io/OdinRockPaperScissor/',
    // },
    // {
    //     id: 30,
    //     image: calculator,
    //     title: 'Calculator',
    //     category: 'beginner',
    //     link: 'https://bbkx226.github.io/OdinCalculator/',
    // },
];

export const projectsNav = [
    {
        name: 'all',
    },
    {
        name: 'web',
    },
    {
        name: 'AI',
    },
    {
        name: 'app',
    },

];