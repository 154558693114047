import React, { useState, useRef } from 'react';
import "./qualification.css";
import { SiGooglegemini } from "react-icons/si";
import { MdArrowDropDownCircle } from "react-icons/md";
import { IoMdArrowDropupCircle } from "react-icons/io";
import { PiStudentFill } from "react-icons/pi";


const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);
    const qualificationRef = useRef(null);
    const titleRef = useRef(null);

    const toggleTab = (index) => {
        setToggleState(index);
        setIsExpanded(false);
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);

        setTimeout(() => {
            if (isExpanded) {
                // If the qualifications are not expanded, scroll to the title
                titleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                // setTimeout(() => window.scrollBy(0, -985), 100); // Delay the call to window.scrollBy
            } else {
                // If the qualifications are expanded, scroll to the 4th qualification
                qualificationRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }, 50);
    };


  return (
    <section className="qualification section">
        <h2 className="section__title wrapper nine">
        <div>
            <div ref={titleRef}></div>
            <h3 className="rotate">
                <span>Q</span>
                <span>u</span>
                <span>a</span>
                <span>l</span>
                <span>i</span>
                <span>f</span>
                <span>i</span>
                <span>c</span>
                <span>a</span>
                <span>t</span>
                <span>i</span>
                <span>o</span>
                <span>n</span>
                <span>s</span>
                {/* Qualifications */}
            </h3>
        </div>

        </h2>
        <span className="section__subtitle">- My personal journey -</span>
        <div className="qualification__container container">
            <div className="qualification__tabs">
                <div className={toggleState === 1 
                    ? "qualification__button qualification__active button--flex" 
                    : "qualification__button button--flex"}
                    onClick={() => toggleTab(1)}
                >
                    <i className="qualification__icon"><SiGooglegemini style={{ position: 'relative', top: '0.1em', marginLeft: '0.2em', width: '70%', height: '70%' }}/></i>{" "} Experience
                </div>

                <div className={toggleState === 2
                    ? "qualification__button qualification__active button--flex" 
                    : "qualification__button button--flex"}
                    onClick={() => toggleTab(2  )}
                >
                    <i className="qualification__icon"><PiStudentFill style={{ position: 'relative', top: '0.1em', marginLeft: '0.2em', width: '70%', height: '70%' }}/></i>{" "} Education 
                </div>
            </div>

            <div className="qualification__sections">
            <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                <div className="qualification__data">
                    <div></div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>

                    <div>
                        <h3 className="qualification__title">Chair of APU IEEE Student Branch</h3>
                        <span className="qualification__subtitle">APU IEEE Student Branch</span>
                        <div className="qualification__calendar">
                            <i className="uil uil-calendar-alt"></i> May 2024 - Feb 2025
                        </div>
                    </div>    
                </div>

                <div className="qualification__data">
                    <div>
                        <h3 className="qualification__title">Webmaster of APU IEEE Student Branch</h3>
                        <span className="qualification__subtitle">APU IEEE Student Branch</span>
                        <div className="qualification__calendar">
                            <i className="uil uil-calendar-alt"></i> May 2024 - Feb 2025
                        </div>
                    </div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>                    
                </div>

                <div className="qualification__data">
                    <div></div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>

                    <div>
                        <h3 className="qualification__title">Full Stack Software Engineer - Contractor</h3>
                        <span className="qualification__subtitle">Ventionex Innovations Sdn. Bhd.</span>
                        <div className="qualification__calendar">
                            <i className="uil uil-calendar-alt"></i> May 2024 - Feb 2025
                        </div>
                    </div>    
                </div>

                {isExpanded && (
                    <>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Full Stack Software Engineer - Intern</h3>
                                <span className="qualification__subtitle">Ventionex Innovations Sdn. Bhd.</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Dec 2023 - Apr 2024
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>                    
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Treasury Department Lead</h3>
                                <span className="qualification__subtitle">APU AWS Cloud Club</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Aug 2023 - Feb 2025
                                </div>
                            </div>    
                        </div>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Chair of Computer Society</h3>
                                <span className="qualification__subtitle">APU IEEE Student Branch</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Apr 2023 - Apr 2024
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>                    
                        </div>
                    </>
                )}
                {/* <div className="qualification__data">
                    <div>
                        <h3 className="qualification__title">Finance Team <br/> - KitaHack 2024</h3>
                        <span className="qualification__subtitle">Google Developer Student Clubs Malaysia <br/> (GDSC Malaysia)</span>
                        <div className="qualification__calendar">
                            <i className="uil uil-calendar-alt"></i> Present
                        </div>
                    </div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>
                </div> */}
                
                {/* <div className="qualification__data" ref={qualificationRef}>
                    <div></div>
                        
                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>

                    <div>
                        <h3 className="qualification__title">Organizing Committee <br/> - IEEE SCOReD Conference 2023</h3>
                        <span className="qualification__subtitle">IEEE APU Student Branch</span>
                        <div className="qualification__calendar">
                            <i className="uil uil-calendar-alt"></i> Aug 2023 - Dec 2023
                        </div>
                    </div>    
                </div> */}
                
                {/* {isExpanded && (
                    <>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Treasury Lead <br/> - Industry Trip to Drone Academy Asia</h3>
                                <span className="qualification__subtitle">Asia Pacific University</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Aug 2023 - Nov 2023
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Organizing Committee <br/>- AWS Jam 2023 @ Malaysia</h3>
                                <span className="qualification__subtitle">Asia Pacific University</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Aug 2023 - Nov 2023
                                </div>
                            </div>    
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Secretary & Facilitator - Employability Trend at Sime Darby Property</h3>
                                <span className="qualification__subtitle">APU AWS Cloud Club</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Aug 2023 - Oct 2023
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Treasury Department Lead</h3>
                                <span className="qualification__subtitle">APU AWS Cloud Club</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Aug 2023 - Present
                                </div>
                            </div>    
                        </div>
                    
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Chair of Computer Society</h3>
                                <span className="qualification__subtitle">IEEE APU Student Branch</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Apr 2023 - Present
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Event Department Lead - Engineer's Day</h3>
                                <span className="qualification__subtitle">Asia Pacific University</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Jul 2023 - Oct 2023
                                </div>
                            </div>    
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Group Treasurer - Captain Ball Tournament</h3>
                                <span className="qualification__subtitle">Asia Pacific University</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Jul 2022 - Dec 2022
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div> 
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Class Treasurer - Fundraising</h3>
                                <span className="qualification__subtitle">Foon Yew High School</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i> Jan 2018 - Dec 2019
                                </div>
                            </div>    
                        </div>
                    </>
                )} */}
                </div>
                
                <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">Asia Pacific University</h3>
                            <span className="qualification__subtitle">Malaysia - Kuala Lumpur</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2022 - 2025
                            </div>
                        </div>    
                    </div>
                    <div className="qualification__data">
                        <div>
                            <h3 className="qualification__title">De Montfort University <br/> - Dual Degree</h3>
                            <span className="qualification__subtitle">Malaysia - Kuala Lumpur</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2022 - 2025
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">University of Helsinki</h3>
                            <span className="qualification__subtitle">- Full Stack Open 2022 Program</span>
                            <span className="qualification__subtitle">Online</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2023
                            </div>
                        </div>    
                    </div>
                    
                    {isExpanded && (
                        <>
                            <div className="qualification__data">
                                <div>
                                    <h3 className="qualification__title">Online Resources</h3>
                                    <span className="qualification__subtitle">Internet</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i> 2018 - Present
                                    </div>
                                </div>

                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>

                                
                            </div>

                            <div className="qualification__data">
                                <div></div>

                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>

                                <div>
                                    <h3 className="qualification__title">Foon Yew High School</h3>
                                    <span className="qualification__subtitle">Malaysia - Johor</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i> 2015 - 2020
                                    </div>
                                </div>    
                            </div>
                        </>
                    )}
                </div>
            </div>
        
            <div className='qualification__button-container'>
                <button onClick={toggleExpand} ref={qualificationRef} className='button'>
                {isExpanded ? <>Show Less <IoMdArrowDropupCircle className= "button__icon__down" style={{ position: 'relative', top: '0.2em' }}/></> : <>Show More <MdArrowDropDownCircle className= "button__icon__up" style={{ position: 'relative', top: '0.2em' }}/></>}                    {/* <span className="bounce" style={{animationDelay: '0s'}}>.</span>
                    <span className="bounce" style={{animationDelay: '0.2s'}}>.</span>
                    <span className="bounce" style={{animationDelay: '0.4s'}}>.</span> */}
                </button>
            </div>
        </div>
    </section>
  )
}

export default Qualification